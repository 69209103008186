export const ITEMS_EVERY_PAGE = 30;

//默认语言
export const DEFAULT_LOCALE = "en";

export const COOKIE_PREFIX = "z";
export const COOKIE_LANG = COOKIE_PREFIX + "lang";
export const COOKIE_EXPIRED_DAYS = 3000;



export const DOMAIN_BASE = document.domain.split('.').slice(-2).join('.');
export const DOMAIN_COOKIE = "." + DOMAIN_BASE;

export const API_URL = 'https://www.vdscool.com/vds-api'
// export const API_URL = 'http://133.130.116.161:8080/vds-api'
