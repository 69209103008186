import axios from 'axios';
import Immutable from 'immutable';
import { message } from 'antd';

const FETCH_TOKEN_LIST = 'FETCH_TOKEN_LIST';
const FETCH_TOKEN_INFO = 'FETCH_TOKEN_INFO';
const FETCH_TOKEN_TXS = 'FETCH_TOKEN_TXS';

import{ ITEMS_EVERY_PAGE, API_URL} from '../../conf'

// const apiUrl = 'http://133.130.116.161:8080/vds-api';
// const apiUrl = 'https://qtum.info/api';


export const fetchTokenList = (offset = 0, limit = 10) => {
    return (dispatch) => {
        return axios.get(`${API_URL}/qrc20`, {
            params: {
                offset: offset,
                limit: limit
            }
        })
        .then(response => {
            let token = response.data;
            dispatch(fetchTokenListSuccess(token))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export const fetchTokenListSuccess = (token) => {
    return {
        type: FETCH_TOKEN_LIST,
        token
    };
};

export const fetchTokenInfo = (address) => {
    return (dispatch) => {
        return axios.get(`${API_URL}/contract/${address}`)
        .then(response => {
            let token = response.data.qrc20;
            dispatch(fetchTokenInfoSuccess(token))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export const fetchTokenInfoSuccess = (token) => {
    return {
        type: FETCH_TOKEN_INFO,
        token
    };
};

export const fetchTokenTxs = ({address, page=0, pageSize=10}) => {
    return (dispatch) => {
        return axios.get(`${API_URL}/qrc20/${address}/txs`, {
            params: {
                page: page,
                pageSize: pageSize
            }
        })
        .then(response => {
            let tokenTxsData = response.data;
            dispatch(fetchTokenTxsSuccess(tokenTxsData))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export const fetchTokenTxsSuccess = (tokenTxsData) => {
    return {
        type: FETCH_TOKEN_TXS,
        tokenTxsData
    };
};


const initPoolState = Immutable.fromJS({
    tokenCount: 0,
    tokens: [],
    tokenInfo: {
        address: '',
        addressHex: '',
        decimals: 8,
        holders: 0,
        name: "",
        symbol: "",
        totalSupply: 0,
        transactions: 0
    },
    tokenTxs: [],
    tokenTxsAmount: 0
});

const tokenReducer = (state = initPoolState, action) => {
    switch (action.type) {
        case FETCH_TOKEN_LIST:
            return state.set('tokenCount', action.token.totalCount).set('tokens', Immutable.fromJS(action.token.tokens));
        case FETCH_TOKEN_INFO:
            return state.set('tokenInfo', Immutable.fromJS(action.token));
        case FETCH_TOKEN_TXS:
            return state.set('tokenTxs', Immutable.fromJS(action.tokenTxsData.transactions)).set('tokenTxsAmount', action.tokenTxsData.totalCount);
        default:
            return state;
    }
}
export default tokenReducer;