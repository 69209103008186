import axios from 'axios';
import Immutable from 'immutable';
import { message } from 'antd';

const FETCH_POOL_REWARD = 'FETCH_POOL_REWARD';
const FETCH_POOL_RATIO = 'FETCH_POOL_RATIO';

const apiUrl = '/koa';


export const fetchPoolReward = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/pool/reward`)
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchPoolRewardSuccess(res.reward))
                } else {
                    message.error(res.message);
                }
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchPoolRewardSuccess = (reward) => {
    return {
        type: FETCH_POOL_REWARD,
        reward
    };
};

export const fetchPoolRatio = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/pool/ratio`)
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchPoolRatioSuccess(res.reward))
                } else {
                    message.error(res.message);
                }
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchPoolRatioSuccess = (ratio) => {
    return {
        type: FETCH_POOL_RATIO,
        ratio
    };
};


const initPoolState = Immutable.fromJS({
    reward: {},
    ratio: {}
});

const poolReducer = (state = initPoolState, action) => {
    switch (action.type) {
        case FETCH_POOL_REWARD:
            return state.set('reward', Immutable.fromJS(action.reward));
        case FETCH_POOL_RATIO:
            return state.set('ratio', Immutable.fromJS(action.ratio))
        default:
            return state;
    }
}
export default poolReducer;