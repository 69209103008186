import Immutable from 'immutable';
import jwtDecode from 'jwt-decode';
import { message } from 'antd';

import axios from '@/util/axios'
import axios2 from 'axios';


import { history } from '../../configureStore';


const USER_LOGIN = 'USER_LOGIN';
const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

const USER_REGISTER = 'USER_REGISTER';
const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

const USER_LOGOUT = 'USER_LOGOUT';

const FETCH_USER_INFO = 'FETCH_USER_INFO';

const FETCH_USER_VIDS = 'FETCH_USER_VIDS';

const FETCH_USER_ADDRESS = 'FETCH_USER_ADDRESS';
const FETCH_USER_MASTERNODE = 'FETCH_USER_MASTERNODE';

const apiUrl = '/koa';
const livenetApiUrl = '/api/BTC/livenet';


export const login = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/user/login`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    localStorage.setItem('buss', res.buss)
                    dispatch(loginSuccess(res.buss));
                    message.success(res.message);
                    history.push("/");
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const loginSuccess = (buss) => {
    return {
        type: USER_LOGIN,
        buss
    };
};

export const register = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/user/register`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const logout = () => {
    return (dispatch) => {
        localStorage.removeItem('buss');
        dispatch(logoutSuccess());
        history.push("/login");
    }
}

export const logoutSuccess = (buss) => {
    return {
        type: USER_LOGOUT
    };
};

export const fetchUserInfo = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/user/info`)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    dispatch(fetchUserInfoSuccess(res.user));
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const fetchUserInfoSuccess = (user) => {
    return {
        type: FETCH_USER_INFO,
        user
    }
};

export const modifyPasswd = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/user/modify_passwd`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    message.success(res.message);
                    history.push("/");
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const bindVid = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/user/bind_vid`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    message.success(res.message);
                    dispatch(fetchUserInfo());
                    history.push("/");
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchVidList = (address) => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/vid/tree`, {
                params: {
                    address: address
                }
            })
            .then(response => {
                if(response.code == 200){
                    dispatch(fetchVidListSuccess(response.result.child));
                }else{
                    message.error(response.message);
                }
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const fetchVidListSuccess = (vids) => {
    return {
        type: FETCH_USER_VIDS,
        vids
    }
};

export const fetchAddress = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/address/list`)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    dispatch(fetchAddressSuccess(res.addresses));
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const fetchAddressSuccess = (addresses) => {
    return {
        type: FETCH_USER_ADDRESS,
        addresses
    }
};

export const createAddress = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/address/create`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    dispatch(fetchAddress());
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const editAddress = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/address/edit`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    dispatch(fetchAddress());
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const deleteAddress = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/address/delete`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    dispatch(fetchAddress());
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const fetchMasternode = (page, pageSize, type, searchText) => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/user/masternode/list`, {
                params: {
                    page: page,
                    pageSize: pageSize,
                    type: type,
                    searchText: searchText
                }
            })
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    dispatch(fetchMasternodeSuccess(res.addresses, res.total));
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const fetchMasternodeSuccess = (masternodes, total) => {
    return {
        type: FETCH_USER_MASTERNODE,
        masternodes,
        total
    }
};

export const createMasternode = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/user/masternode/create`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    dispatch(fetchMasternode());
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const editMasternode = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/user/masternode/edit`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    dispatch(fetchMasternode());
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const deleteMasternode = (params) => {
    return (dispatch) => {
        return axios.post(`${apiUrl}/user/masternode/delete`, params)
            .then(response => {
                let res = response;
                if (res.code === 200) {
                    dispatch(fetchMasternode());
                    message.success(res.message);
                } else {
                    message.error(res.message);
                }
                return res;
            })
            .catch(error => {
                throw(error);
            });
      };
};

let initUserState = Immutable.fromJS({
    email: '',
    status: 0,
    token: '',
    vid: '',
    addresses: [],
    masternodes: [],
    masternodesTotal: 0,
    vids: []
});

if (!!localStorage.getItem('buss') && localStorage.getItem('buss') !== 'undefined') {
    const { email, status, token } = jwtDecode(localStorage.buss);
    initUserState = initUserState.set('email', email).set('status', status).set('token', token);
}

const userReducer = (state = initUserState, action) => {
    switch (action.type) {
        case USER_LOGIN:
            const { token, status, email } = jwtDecode(action.buss);
            return state.set('email', email).set('status', status).set('token', token);
        case FETCH_USER_INFO:
            return state.set('vid', action.user.vid).set('random', action.user.random);
        case USER_LOGOUT:
            return state.set('email', '').set('status', 0).set('token', '');
        case FETCH_USER_ADDRESS:
            return state.set('addresses', Immutable.fromJS(action.addresses));
        case FETCH_USER_MASTERNODE:
            return state.set('masternodes', Immutable.fromJS(action.masternodes)).set('masternodesTotal', action.total);
        case FETCH_USER_VIDS:
            return state.set('vids', Immutable.fromJS(action.vids));
        default:
            return state;
    }
}
export default userReducer;