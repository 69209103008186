import cookie from 'js-cookie';
import Immutable from 'immutable';

import { DEFAULT_LOCALE, COOKIE_LANG, DOMAIN_COOKIE, COOKIE_EXPIRED_DAYS } from '../../conf'

const SET_LANG = 'SET_LANG';

export const setLang = (lang) => {
   let localeForCookie;
   switch(lang) {
       case 'zh':
           localeForCookie = "zh";
           break;
       case 'en':
           localeForCookie = "en";
           break;
       default:
           localeForCookie = "zh";
           lang = 'zh';
   }
   
   cookie.set(COOKIE_LANG, localeForCookie, {
       expires: COOKIE_EXPIRED_DAYS,
       domain: DOMAIN_COOKIE,
       path: '/'
   })
   return {
       type: SET_LANG,
       lang
   };
}
const getLocale = () => {
    let localeCookie = cookie.get(COOKIE_LANG);
    let locale ;
    if(!localeCookie){
        localeCookie = navigator.language.split('-')[0];
    }
    switch(localeCookie) {
        case 'zh':
            locale = "zh";
            break;
        case 'en':
            locale = "en";
            break;
        default:
            locale = DEFAULT_LOCALE;
    }
    return locale;
}
const initialLanguageState = Immutable.fromJS({
    lang: getLocale()
});

const reducer = (state = initialLanguageState, action) => {
   switch(action.type) {
       case SET_LANG:
            return state.set('lang', action.lang);;
       default:
           return state;
   }
}

export default reducer;