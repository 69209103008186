import moment from 'moment'
import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Input, message } from 'antd';
const Search = Input.Search;


import{ API_URL, ITEMS_EVERY_PAGE } from '../../conf'
import { history } from '../../configureStore';


const apiUrl = '/api/BTC/livenet';

class Searchbar extends React.Component {
    state = {
        searchText: ''
    }
    constructor(props){
        super(props);
    }

    goBlockPage = (hash) => {
        history.push(`/block/${hash}`);
    }
    goTxPage = (hash) => {
        history.push(`/tx/${hash}`);
    }
    goAddressPage = (hash) => {
        history.push(`/address/${hash}`);
    }

    search = (searchText) => {
        var searchText = searchText.replace(/\s/g, '');
        if(!searchText){
            message.warning('No matching records found!', 3);
            return;
        }
        try{
            axios.get(`${API_URL}/search?query=${searchText}`).then(res => {
                let { type, id, addressHex } = res['data'];
                if(typeof type === 'undefined'){
                    message.warning('No matching records found!', 3);
                    return;
                }
                switch (type) {
                    case "address":
                        this.setState({searchText: ''});
                        history.push(`/address/${searchText}`);
                        break;
                    case "block":
                        this.setState({searchText: ''});
                        history.push(`/block/${searchText}`);
                        break;
                    case "contract":
                        this.setState({searchText: ''});
                        if (addressHex) {
                            history.push(`/contract/${addressHex}`);
                        } else {
                            history.push(`/contract/${searchText}`);
                        }
                        break;
                    case "transaction":
                        this.setState({searchText: ''});
                        history.push(`/tx/${searchText}`);
                        break;
                }
            });
        }catch(err){
            message.warning('No matching records found!', 3);
        }
    }
    
    componentDidMount() {
        //
    }
    componentWillUnmount() {
        //clearInterval(this.interval);
    }
    render() {
        return (
            <Search
                className="search-input"
                value={this.state.searchText}
                onChange={e => {this.setState({searchText: e.target.value})}}
                placeholder="Search for block, transaction or address"
                onSearch={value => {this.search(value)}}
            />
        )
    }
}

export default Searchbar;
