import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import masternodes from './modules/masternodes';
import mnonline from './modules/mnonline';
import user from './modules/user';
import block from './modules/block';
import tx from './modules/tx';
import address from './modules/address';
import market from './modules/market';
import pool from './modules/pool';
import token from './modules/token';
import contract from './modules/contract';
import locale from './modules/locale';


const rootReducer = (history) => combineReducers({
  masternodes,
  mnonline,
  user,
  block,
  tx,
  address,
  market,
  pool,
  token,
  contract,
  locale,
  router: connectRouter(history)
})

export default rootReducer