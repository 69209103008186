import axios from 'axios';
import Immutable from 'immutable';

const FETCH_MNONLINE = 'FETCH_MNONLINE';


const apiUrl = '/mn';


export const fetchMnonline = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/mnonline.json`)
            .then(response => {
                dispatch(fetchMnonlineSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
      };
};

export const fetchMnonlineSuccess = (mnonline) => {
    return {
        type: FETCH_MNONLINE,
        mnonline
    };
};

const initMnonlineState = Immutable.fromJS({
    mnonline: [],
    isFetching: false,
    isLoading: false
});

const mnonlineReducer = (state = initMnonlineState, action) => {
    switch (action.type) {
        case FETCH_MNONLINE:
            action.mnonline = action.mnonline.sort((a, b)=> { return b.lastseen - a.lastseen;});
            return state.set('mnonline', action.mnonline);
        default:
            return state;
    }
}
export default mnonlineReducer;