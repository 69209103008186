import axios from 'axios';
import Immutable from 'immutable';
import * as _ from 'lodash';

import{ ITEMS_EVERY_PAGE, API_URL } from '../../conf'



const FETCH_LATEST_BLOCK = 'FETCH_LATEST_BLOCK';
const FETCH_LATEST_BLOCK_LOADING = 'FETCH_LATEST_BLOCK_LOADING';
const FETCH_BLOCKS = 'FETCH_BLOCKS';
const FETCH_NEWEST_BLOCKS = 'FETCH_NEWEST_BLOCKS';
const FETCH_BLOCK_DETAIL = 'FETCH_BLOCK_DETAIL';
const FETCH_BLOCK_DETAIL_LOADING = 'FETCH_BLOCK_DETAIL_LOADING';
const FETCH_BLOCK_TXS = 'FETCH_BLOCK_TXS';


const apiUrl = '/api/BTC/livenet';


export const fetchLatestBlock = () => {
    return (dispatch) => {
        dispatch(fetchLatestBlockLoading());
        return axios.get(`${API_URL}/recent-blocks`, {
                params: {
                    count: 10
                }
            })
            .then(response => {
                dispatch(fetchLatestBlockSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
      };
};
export const fetchLatestBlockLoading = () => {
    return {
        type: FETCH_LATEST_BLOCK_LOADING
    };
};
export const fetchLatestBlockSuccess = (blocks) => {
    return {
        type: FETCH_LATEST_BLOCK,
        blocks
    };
};

export const fetchBlocks = (date) => {
    return (dispatch) => {
        return axios.get(`${API_URL}/blocks`, {
            params: {
                date: date
            } 
        })
            .then(response => {
                dispatch(fetchBlocksSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchBlocksSuccess = (blocks) => {
    return {
        type: FETCH_BLOCKS,
        payload: {
            blocks
        }
    };
};

export const fetchNewestBlock = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/block/tip`)
            .then(response => {
                dispatch(fetchNewestBlockSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchNewestBlockSuccess = (block) => {
    return {
        type: FETCH_NEWEST_BLOCKS,
        block
    };
};

export const fetchBlockDetail = (hash) => {
    return (dispatch) => {
        dispatch(fetchBlockDetailLoading());
        return axios.get(`${API_URL}/block/${hash}`)
            .then(response => {
                dispatch(fetchBlockDetailSuccess(response.data));
                return response.data;
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchBlockDetailLoading = () => {
    return {
        type: FETCH_BLOCK_DETAIL_LOADING
    };
};
export const fetchBlockDetailSuccess = (detail) => {
    return {
        type: FETCH_BLOCK_DETAIL,
        detail
    };
};

export const fetchBlockTxs = (hash) => {
    return (dispatch) => {
        return axios.get(`${API_URL}/tx?blockHash=${hash}`)
            .then(response => {
                let txs = response.data;
                txs = txs.sort((a, b)=> {
                    if(a['coinbase']){
                        return -1;
                    }else if(b['coinbase']){
                        return 1;
                    }
                })
                dispatch(fetchBlockTxsSuccess(txs))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchBlockTxsSuccess = (txs) => {
    return {
        type: FETCH_BLOCK_TXS,
        txs
    };
};

const initBlockState = Immutable.fromJS({
    latestBlocks: [],
    latestBlocksLoading: false,
    blocks: [],
    newestBlock: {
        bits: 476216888,
        chain: "BTC",
        confirmations: -6336,
        hash: "e626c1d295ab6647039c8446acda6d87878f26ceaa90aa6531af34f31cbe42e2",
        height: 116908,
        merkleRoot: "eb9793946500b417cb8922cd8438f74f7596c6636ed780ed3df44c86a30ade78",
        network: "livenet",
        nextBlockHash: "2a8606f3d57698c83044d30f80fb6e5a3f9215be4eecfdd88a80cfb4c4cd10ee",
        nonce: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADKW3aRoWh3M=",
        previousBlockHash: "3be07cd30e620605b77d5448455b4de843282390280e9b156d4e94659c717199",
        reward: 25495323216,
        size: 17938,
        time: "2019-05-16T02:44:29.000Z",
        timeNormalized: "2019-05-16T02:44:29.000Z",
        transactionCount: 34,
        version: 536870912,
        _id: "5cdccf27d9f5176b2811f5fb",
        coinbaseCoins: []
    },
    blockDetail: {
        bits: 476216888,
        chain: "BTC",
        confirmations: 0,
        hash: "e626c1d295ab6647039c8446acda6d87878f26ceaa90aa6531af34f31cbe42e2",
        height: 0,
        merkleRoot: "eb9793946500b417cb8922cd8438f74f7596c6636ed780ed3df44c86a30ade78",
        network: "livenet",
        nextBlockHash: "2a8606f3d57698c83044d30f80fb6e5a3f9215be4eecfdd88a80cfb4c4cd10ee",
        nonce: "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADKW3aRoWh3M=",
        previousBlockHash: "3be07cd30e620605b77d5448455b4de843282390280e9b156d4e94659c717199",
        reward: 25495323216,
        size: 17938,
        time: "2019-05-16T02:44:29.000Z",
        timeNormalized: "2019-05-16T02:44:29.000Z",
        transactionCount: 34,
        version: 536870912,
        _id: "5cdccf27d9f5176b2811f5fb",
        coinbaseCoins: [],
        transactions: []
    },
    blockDetailLoading: false,
    blockTxs: []
});

const blockReducer = (state = initBlockState, action) => {
    switch (action.type) {
        case FETCH_LATEST_BLOCK:
            return state.set('latestBlocks', Immutable.fromJS(action.blocks)).set('latestBlocksLoading', false);
        case FETCH_LATEST_BLOCK_LOADING:
            return state.set('latestBlocksLoading', true)
        case FETCH_BLOCKS:
            return state.set('blocks', Immutable.fromJS(action.payload.blocks)); 
        case FETCH_NEWEST_BLOCKS:
            return state.set('newestBlock', Immutable.fromJS(action.block));
        case FETCH_BLOCK_DETAIL:
            return state.set('blockDetail', Immutable.fromJS(action.detail)).set('blockDetailLoading', false);
        case FETCH_BLOCK_DETAIL_LOADING:
            return state.set('blockDetailLoading', true);
        case FETCH_BLOCK_TXS:
            return state.set('blockTxs', Immutable.fromJS(action.txs));
        default:
            return state;
    }
}
export default blockReducer;