import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Row, Col, Menu, Icon } from 'antd';
import { UserOutlined } from '@ant-design/icons';
const SubMenu = Menu.SubMenu;
import { FormattedMessage } from 'react-intl';


import { logout } from '../../../redux/modules/user';
import Searchbar from '../../../components/search';


class InsightHeader extends React.Component {

    state = {
        menuShown: false
    }

    handleLogout = () => {
        this.props.onLogout();
    }

    toggleMenu = () => {
        this.setState({
            menuShown: !this.state.menuShown
        })
    }
    
    render() {
        const { email, status } = this.props;

        return (
            <div className="navbar navbar-default navbar-fixed-top">
                <div className="container">
                    <div className="navbar-header">
                        <Link className="insight navbar-brand" to="/">V-Dimension</Link>
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse" onClick={this.toggleMenu}>
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>
                    
                    <div className={"navbar-collapse collapse"+(this.state.menuShown?" in":"")}>
                        <ul className="nav navbar-nav">
                            <li>
                                <Link to="/blocks"><FormattedMessage id="nav.block" /></Link>
                            </li>
                            <li>
                                <Link to="/masternodes"><FormattedMessage id="nav.masternode" /></Link>
                            </li><li>
                                <Link to="/tokens"><FormattedMessage id="nav.token" /></Link>
                            </li>
                            <li>
                                <Link to="/rich"><FormattedMessage id="nav.rich" /></Link>
                            </li>
                            {/* <li>
                                <Link to="/masternodes">Masternode</Link>
                            </li>
                            <li>
                                <Link to="/mnonline">Mnonline</Link>
                            </li> */}
                        </ul>
                        <div className="navbar-form navbar-left">
                            <Searchbar />
                        </div>
                        {
                            email ? (
                                <div className="nav navbar-nav navbar-right">
                                    <Menu
                                        mode="horizontal"
                                        selectable={false}
                                    >
                                        <SubMenu title={<span className="submenu-title-wrapper"><UserOutlined />{email}</span>}>
                                            <Menu.Item key="setting:1"><Link to="/my/token"><FormattedMessage id="nav.usercenter" /></Link></Menu.Item>
                                            <Menu.Item key="setting:2"><Link to="/my/address"><FormattedMessage id="nav.useraddress" /></Link></Menu.Item>
                                            <Menu.Item key="setting:3"><Link to="/my/masternodes"><FormattedMessage id="nav.usermasternode" /></Link></Menu.Item>
                                            <Menu.Item key="setting:4"><Link to="/my/vidlist"><FormattedMessage id="nav.vid_system" /></Link></Menu.Item>
                                            <Menu.Item key="setting:5" onClick={this.handleLogout}><FormattedMessage id="nav.logout" /></Menu.Item>
                                        </SubMenu>
                                    </Menu>
                                </div>
                            ) : (
                                <ul className="nav navbar-nav navbar-right">
                                    <li>
                                        <Link to="/login"><FormattedMessage id="nav.login" /></Link>
                                    </li>
                                    <li>
                                        <Link to="/signup"><FormattedMessage id="nav.signup" /></Link>
                                    </li>
                                </ul>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return{
        email: state.getIn(['user', 'email'])
    }
 }
const mapDispatchToProps = (dispatch) => {
    return{
        onLogout: (values) => {
            dispatch(logout())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InsightHeader);