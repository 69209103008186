import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

import { setLang } from '../../../redux/modules/locale';

class InsightFooter extends React.Component {

    setLanguage = (lang) => {
        this.props.onSetLang(lang);
    }
    
    render() {

        return (
            <div className="footer" id="footer">
                <div className="container">
                    <div className="links pull-left">
                        <span className="languages">
                        [
                            <a href="javascript:void(0)" onClick={()=> {this.setLanguage('en')}}>
                                <span className="ng-hide"> · </span> English
                            </a>
                            <a href="javascript:void(0)" onClick={()=> {this.setLanguage('zh')}}>
                                <span> · </span> 简体中文&nbsp;
                            </a>
                        ]
                        </span>&nbsp;
                        [&nbsp;
                            <Link to="/verify"><FormattedMessage id="verify.title" /></Link>
                            {/* ·
                            <Link to="/broadcast"><FormattedMessage id="broadcast.title" /></Link> */}
                        &nbsp;]
                    </div>
                    <span className="logo">VDS.COOL</span>
                    <span className="version">v0.1.0-beta</span>                        
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return{
        
    }
 }
const mapDispatchToProps = (dispatch) => {
    return{
        onSetLang: (lang) => {
            dispatch(setLang(lang))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(InsightFooter);
