import axios from 'axios';
import Immutable from 'immutable';
import _ from 'lodash';
import{ ITEMS_EVERY_PAGE, API_URL} from '../../conf'

const FETCH_ADDRESS_BALANCE = 'FETCH_ADDRESS_BALANCE';
const FETCH_ADDRESS_TXS = 'FETCH_ADDRESS_TXS';
const FETCH_ADDRESS_TXS_LOADING = 'FETCH_ADDRESS_TXS_LOADING';
const FETCH_ADDRESS_VID_AMOUNT = 'FETCH_ADDRESS_VID_AMOUNT';

const apiUrl = '/api/BTC/livenet';
const COIN = 100000000;

export const fetchAccountInfo = (hash) => {
    return (dispatch) => {
        return axios.get(`${API_URL}/address/${hash}`)
            .then(response => {
                dispatch(fetchAccountInfoSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchAccountInfoSuccess = (accountInfo) => {
    return {
        type: FETCH_ADDRESS_BALANCE,
        accountInfo
    };
};

export const fetchAddressTxs = (data) => {
    return (dispatch) => {
        dispatch(fetchAddressTxsLoading());
        const { skip = 0, limit = 10, address} = data;
        return axios.get(`${API_URL}/address/${address}/basic-txs?offset=${skip}&limit=${limit}`)
            .then(response => {
                //let res = orderByHeight(response.data);
                dispatch(fetchAddressTxsSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchAddressTxsLoading = () => {
    return {
        type: FETCH_ADDRESS_TXS_LOADING
    };
};
export const fetchAddressTxsSuccess = (result) => {
    return {
        type: FETCH_ADDRESS_TXS,
        result
    };
};

export const fetchAddressVidAmount = (hash) => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/address/${hash}/vid_amount`)
            .then(response => {
                let res = response.data;
                dispatch(fetchAddressVidAmountSuccess(res))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchAddressVidAmountSuccess = (vidAmount) => {
    return {
        type: FETCH_ADDRESS_VID_AMOUNT,
        vidAmount
    };
};

const initAddressState = Immutable.fromJS({
    accountInfo: {
        balance: 0,
        totalReceived: 0,
        totalSent: 0,
        unconfirmed: 0
    },
    accountTxs: [],
    accountTxsAmount: 0,
    txsLoading: false,
    txsHasMore: true,
    vidAmount: {
        season: 0,
        total: 0
    }
});

const addressReducer = (state = initAddressState, action) => {
    switch (action.type) {
        case FETCH_ADDRESS_BALANCE:
            return state.set('accountInfo', Immutable.fromJS(action.accountInfo));
        case FETCH_ADDRESS_TXS:
            return state.set('accountTxs', Immutable.fromJS(action.result.transactions)).set('txsLoading', false).set('accountTxsAmount', action.result.totalCount);
            // let txsHasMore = action.txs.length < ITEMS_EVERY_PAGE?false:true;
            // if(action.skip == 0){
            //     return state.set('txs', Immutable.fromJS(action.txs)).set('txsLoading', false).set('txsHasMore', txsHasMore);
            // }else{
            //     return state.update('txs', txs => txs.concat(action.txs)).set('txsLoading', false).set('txsHasMore', txsHasMore);
            // }
            
        case FETCH_ADDRESS_TXS_LOADING:
            return state.set('txsLoading', true);
        case FETCH_ADDRESS_VID_AMOUNT:
        return state.set('vidAmount', Immutable.fromJS(action.vidAmount));
        default:
            return state;
    }
}
export default addressReducer;