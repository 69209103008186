import React from "react";
import { Router, Route, Link, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import loadable from '@loadable/component'

import { history } from '../configureStore'
import { fetchNewestBlock } from '../redux/modules/block';
import { fetchUserInfo } from '../redux/modules/user';
import { fetchVidRank, fetchLastSeasonVidRank } from '../redux/modules/tx';
import InsightHeader from './common/header/header';
import InsightFooter from './common/footer/footer';

const Home = loadable(() => import('./home'))
const Masternodes = loadable(() => import('./masternodes'))
const Masternode = loadable(() => import('./masternode'))
const Blocks = loadable(() => import('./blocks'))
const Block = loadable(() => import('./block'))
const Tx = loadable(() => import('./tx'))
const ErrorTx = loadable(() => import('./tx/error'))
const Address = loadable(() => import('./address'))
const Pool = loadable(() => import('./pool'))
const Rich = loadable(() => import('./rich'))
const TokenList = loadable(() => import('./token/list'))
const Token = loadable(() => import('./token/detail'))
const Contract = loadable(() => import('./contract/detail'))

// const Mnonline = loadable(() => import('./mnonline'))
const Login = loadable(() => import('./login'))
const Signup = loadable(() => import('./signup'))
const UserPasswd = loadable(() => import('./my/passwd'))
const UserVid = loadable(() => import('./my/vid'))
const UserVidList = loadable(() => import('./my/vid/list'))
const UserToken = loadable(() => import('./my/token'))
const UserAddress = loadable(() => import('./my/address'))
const UserMasternode = loadable(() => import('./my/masternodes'))
const VerifyMessage = loadable(() => import('./verify_message'))
const Broadcast = loadable(() => import('./broadcast'))

import '../assets/css/bootstrap.min.css';
import '../assets/css/app.css';


class App extends React.Component {

  componentDidMount() {
    // this.props.onFetchNewestBlock();
    // this.interval = setInterval(() => {
    //   this.props.onFetchNewestBlock();
    // }, 10000)

    if (!!localStorage.getItem('buss') && localStorage.getItem('buss') !== 'undefined') {
      this.props.onFetchUserInfo();
    }
  }

  componentDidUpdate (prevProps, prevState){
    //console.log(prevProps.newestBlock.height)
    if(prevProps.newestBlock.height != this.props.newestBlock.height){
        //console.log(prevProps.newestBlock.height)
        this.props.onFetchVidRank(this.props.newestBlock.height);
        this.props.onFetchLastSeasonVidRank(this.props.newestBlock.height);
    }
    
  }
  render() {
       return(
        <Router history={history}>
          <InsightHeader/>
          <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/blocks" component={Blocks} />
              <Route path="/block/:hash" component={Block} />
              <Route path="/txs/error" exact component={ErrorTx} />
              <Route path="/tx/:hash" component={Tx} />
              <Route path="/address/:hash" component={Address} />
              <Route path="/masternodes" component={Masternodes} />
              <Route path="/masternode/:txid/:outnum" component={Masternode} />
              <Route path="/tokens" component={TokenList} />
              <Route path="/token/:hash" component={Token} />
              <Route path="/contract/:hash" component={Contract} />
              <Route path="/pool" component={Pool} />
              <Route path="/rich" component={Rich} />
              {/* <Route path="/mnonline" component={Mnonline} /> */}
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/my/passwd" component={UserPasswd} />
              <Route path="/my/vid" component={UserVid} />
              <Route path="/my/vidlist" component={UserVidList} />
              <Route path="/my/token" component={UserToken} />
              <Route path="/my/address" component={UserAddress} />
              <Route path="/my/masternodes" component={UserMasternode} />
              <Route path="/verify" component={VerifyMessage} />
              <Route path="/broadcast" component={Broadcast} />
          </Switch>
          <InsightFooter/>
        </Router>
      )
  }
}

const mapStateToProps = (state, ownProps) => {
  return{
    newestBlock: state.getIn(['block', 'newestBlock']).toJS(),
    vidRank: state.getIn(['tx', 'vidRank']).toJS(),
    vidRankLastSeason: state.getIn(['tx', 'vidRankLastSeason']).toJS(),
  }
}
const mapDispatchToProps = (dispatch) => {
  return{
    onFetchNewestBlock: () => {
      dispatch(fetchNewestBlock())
    },
    onFetchVidRank: (blockHeight) => {
      dispatch(fetchVidRank(blockHeight))
    },
    onFetchLastSeasonVidRank: (blockHeight) => {
        dispatch(fetchLastSeasonVidRank(blockHeight))
    },
    onFetchUserInfo: () => {
      dispatch(fetchUserInfo())
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);