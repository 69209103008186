import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

class LocaleProvider extends React.PureComponent {
    
    render() {
        return (
            <IntlProvider locale={this.props.lang} key={this.props.lang} messages={this.props.messages[this.props.lang]}>
                {this.props.children}
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.getIn(['locale', 'lang'])
    }
}

export default connect(mapStateToProps)(LocaleProvider);