import axios from 'axios';
import Immutable from 'immutable';

import { computeSesaon } from '../../util'
import { API_URL } from '../../conf'

const FETCH_TX_INFO = 'FETCH_TX_INFO';
const FETCH_TX_DETAIL = 'FETCH_TX_DETAIL';
const FETCH_TX_INFO_LOADING = 'FETCH_TX_INFO_LOADING';
const FETCH_VID_TX_COUNT = 'FETCH_VID_TX_COUNT';
const FETCH_VID_RANK = 'FETCH_VID_RANK';
const FETCH_LAST_SEASON_VID_RANK = 'FETCH_LAST_SEASON_VID_RANK';
const FETCH_LATEST_TX = 'FETCH_LATEST_TX';
const FETCH_LATEST_TX_LOADING = 'FETCH_LATEST_TX_LOADING';
const FETCH_ERROR_TX = 'FETCH_ERROR_TX';

const apiUrl = '/api/BTC/livenet';

const koaApiUrl = '/koa';

export const fetchTxInfo = (hash) => {
    return (dispatch) => {
        dispatch(fetchTxInfoLoading());
        return axios.get(`${API_URL}/tx/${hash}`)
            .then(response => {
                dispatch(fetchTxInfoSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};
export const fetchTxInfoLoading = () => {
    return {
        type: FETCH_TX_INFO_LOADING
    };
};
export const fetchTxInfoSuccess = (info) => {
    return {
        type: FETCH_TX_INFO,
        info
    };
};

export const fetchTxDetail = (hash) => {
    return (dispatch) => {
        
        return axios.get(`${apiUrl}/tx/${hash}/coins/`)
            .then(response => {
                dispatch(fetchTxDetailSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchTxDetailSuccess = (detail) => {
    return {
        type: FETCH_TX_DETAIL,
        detail
    };
};

export const fetchLatestTransaction = () => {
    return (dispatch) => {
        dispatch(fetchLatestTransactionLoading());
        return axios.get(`${API_URL}/recent-txs`)
            .then(response => {
                dispatch(fetchLatestTransactionSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};
export const fetchLatestTransactionLoading = () => {
    return {
        type: FETCH_LATEST_TX_LOADING
    };
};
export const fetchLatestTransactionSuccess = (latestTx) => {
    return {
        type: FETCH_LATEST_TX,
        latestTx
    };
};

export const fetchErrorTx = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/tx`, {
                params: {
                    blockHeight: -1
                }
            })
            .then(response => {
                dispatch(fetchErrorTxSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchErrorTxSuccess = (txs) => {
    return {
        type: FETCH_ERROR_TX,
        txs
    };
};

export const fetchVidTxCount = (startBlockHeight) => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/stats/vid_count`, {
                params: {
                    from: startBlockHeight
                }
            })
            .then(response => {
                dispatch(fetchVidTXCountSuccess(response.data))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchVidTXCountSuccess = (vidCount) => {
    return {
        type: FETCH_VID_TX_COUNT,
        vidCount
    };
};


export const fetchVidRank = (blockHeight) => {
    const season = computeSesaon(blockHeight);
    return (dispatch) => {
        return axios.get(`${koaApiUrl}/vid_rank`, {
                params: {
                    season: season,
                    size: 20
                }
            })
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchVidRankSuccess(res['clueInfo']));
                } else {
                    //message.error(res.message);
                }
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchVidRankSuccess = (clueInfo) => {
    return {
        type: FETCH_VID_RANK,
        clueInfo
    };
};

export const fetchLastSeasonVidRank = (blockHeight) => {
    const season = computeSesaon(blockHeight) - 1;
    return (dispatch) => {
        return axios.get(`${koaApiUrl}/vid_rank`, {
                params: {
                    season: season,
                    size: 20
                }
            })
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchLastSeasonVidRankSuccess(res['clueInfo']));
                } else {
                    //message.error(res.message);
                }
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchLastSeasonVidRankSuccess = (clueInfo) => {
    return {
        type: FETCH_LAST_SEASON_VID_RANK,
        clueInfo
    };
};

const initTxState = Immutable.fromJS({
    info: {
        inputs: [],
        outputs: []
    },
    txInfoLoading: false,
    detail: {
        inputs: [],
        outputs: []
    },
    vidCount: {
        currentVidCount: 0,
        totalVidCount: 0
    },
    vidRank: [],
    vidRankLastSeason: [],
    latestTx: [],
    latestTxLoading: false,
    errorTxs: []
});

const txReducer = (state = initTxState, action) => {
    switch (action.type) {
        case FETCH_TX_INFO:
            return state.set('info', Immutable.fromJS(action.info)).set('txInfoLoading', false);
            // return state.updateIn(['info', 'inputs'], () => action.info.inputs).updateIn(['info', 'outputs'], () => action.info.outputs).set('txInfoLoading', false);
        case FETCH_TX_DETAIL:
            return state.set('detail', Immutable.fromJS(action.detail));
        case FETCH_TX_INFO_LOADING:
            return state.set('txInfoLoading', true);
        case FETCH_VID_TX_COUNT:
            return state.set('vidCount', Immutable.fromJS(action.vidCount));
        case FETCH_VID_RANK:
            return state.set('vidRank', Immutable.fromJS(action.clueInfo));
        case FETCH_LAST_SEASON_VID_RANK:
            return state.set('vidRankLastSeason', Immutable.fromJS(action.clueInfo));
        case FETCH_LATEST_TX_LOADING:
            return state.set('latestTxLoading', true);
        case FETCH_LATEST_TX:
            return state.set('latestTx', Immutable.fromJS(action.latestTx)).set('latestTxLoading', false);
        case FETCH_ERROR_TX:
            return state.set('errorTxs', Immutable.fromJS(action.txs));
        default:
            return state;
    }
}
export default txReducer;