import { createBrowserHistory, createHashHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router/immutable'
import createRootReducer from './redux/reducers'

export const history = createBrowserHistory()
//export const history = createHashHistory()

export default function configureStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
      ),
      applyMiddleware(thunk)
    ),
  )

  return store
}
