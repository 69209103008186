import { Provider } from 'react-redux'
import Immutable from 'immutable'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './pages/app'
import configureStore from './configureStore'
import LocaleProvider from './components/localeProvider'
import translationMessages from './locale'


const initialState = Immutable.Map()
const store = configureStore(initialState)
const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <LocaleProvider messages={translationMessages}>
        <App />
      </LocaleProvider>
    </Provider>,
    document.getElementById('wrap')
  )
}

render();
