import axios from 'axios';
import Immutable from 'immutable';
import { message } from 'antd';

const FETCH_MASTERNODES = 'FETCH_MASTERNODES';
const FETCH_MASTERNODES_LOADING = 'FETCH_MASTERNODES_LOADING';
const FETCH_MASTERNODES_COUNT = 'FETCH_MASTERNODES_COUNT';

const FETCH_MASTERNODE_DAILY = 'FETCH_MASTERNODE_DAILY';
const FETCH_MASTERNODE_INFO = 'FETCH_MASTERNODE_INFO';
const FETCH_MASTERNODE_STATS = 'FETCH_MASTERNODE_STATS';
const FETCH_MASTERNODE_STATS_LOADING = 'FETCH_MASTERNODE_STATS_LOADING';
const FETCH_MASTERNODES_AMOUNT = 'FETCH_MASTERNODES_AMOUNT';

const apiUrl = '/koa';


export const fetchMasternodes = (page = 1, pageSize = 10, type = '', searchText = '') => {
    return (dispatch) => {
        dispatch(fetchMasternodesLoading());
        return axios.get(`${apiUrl}/masternode/list`, {
            params: {
                page: page,
                pageSize: pageSize,
                type: type,
                searchText: searchText
            }
        })
        .then(response => {
            let res = response.data;
            if (res.code === 200) {
                dispatch(fetchMasternodesSuccess(res.masternodes))
            } else {
                message.error(res.message);
            }
            
        })
        .catch(error => {
            throw(error);
        });
};
};

export const fetchMasternodesLoading = () => {
    return {
        type: FETCH_MASTERNODES_LOADING
    };
};
export const fetchMasternodesSuccess = (masternodes) => {
    return {
        type: FETCH_MASTERNODES,
        masternodes
    };
};

export const fetchMasternodeDailyRecord = (ip) => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/masternode/${ip}/daily`)
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchMasternodeDailyRecordSuccess(res.records))
                } else {
                    message.error(res.message);
                }
                
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchMasternodeDailyRecordSuccess = (records) => {
    return {
        type: FETCH_MASTERNODE_DAILY,
        records
    };
};

export const fetchMasternodeStatsRecord = (txid, outnum) => {
    return (dispatch) => {
        dispatch(fetchMasternodeStatsRecordLoading());
        return axios.get(`${apiUrl}/masternode/${txid}/${outnum}/stats`)
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchMasternodeStatsRecordSuccess(res.records))
                } else {
                    message.error(res.message);
                }
                
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchMasternodeStatsRecordLoading = () => {
    return {
        type: FETCH_MASTERNODE_STATS_LOADING
    };
};
export const fetchMasternodeStatsRecordSuccess = (records) => {
    return {
        type: FETCH_MASTERNODE_STATS,
        records
    };
};

export const fetchMasternodeInfo = (txid, outnum) => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/masternode/${txid}/${outnum}/info`)
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchMasternodeInfoSuccess(res.masternode))
                } else {
                    message.error(res.message);
                }
                
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchMasternodeInfoSuccess = (masternode) => {
    return {
        type: FETCH_MASTERNODE_INFO,
        masternode
    };
};

export const fetchMasternodesCount = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/masternode/count`)
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchMasternodesCountSuccess(res))
                } else {
                    message.error(res.message);
                }
                
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchMasternodesCountSuccess = (result) => {
    return {
        type: FETCH_MASTERNODES_COUNT,
        result
    };
};

export const fetchMasternodesAmount = () => {
    return (dispatch) => {
        return axios.get(`${apiUrl}/masternode_amount`)
            .then(response => {
                let res = response.data;
                if (res.code === 200) {
                    dispatch(fetchMasternodesAmountSuccess(res.result))
                } else {
                    message.error(res.message);
                }
                
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchMasternodesAmountSuccess = (masternodesAmount) => {
    return {
        type: FETCH_MASTERNODES_AMOUNT,
        masternodesAmount
    };
};

const initMasternodeState = Immutable.fromJS({
    masternodes: [],
    masternodesLoading: false,
    masternodesAmount: [],
    count: 0,
    countByType: {},
    total: 0,
    awardMnAmount: 0,
    masternode: {
        address: ''
    },
    dailyRecords: [],
    statsRecords: [],
    statsRecordsLoading: false
});

const masternodeReducer = (state = initMasternodeState, action) => {
    switch (action.type) {
        case FETCH_MASTERNODES:
            return state.set('masternodes', Immutable.fromJS(action.masternodes.rows)).set('count', action.masternodes.count).set('masternodesLoading', false);
        case FETCH_MASTERNODES_LOADING:
            return state.set('masternodesLoading', true);
        case FETCH_MASTERNODES_COUNT:
            return state.set('countByType', Immutable.fromJS(action.result.result)).set('total', action.result.total).set('awardMnAmount', action.result.awardMnAmount);
        case FETCH_MASTERNODE_INFO:
            return state.set('masternode', Immutable.fromJS(action.masternode))
        case FETCH_MASTERNODE_DAILY:
            return state.set('dailyRecords', Immutable.fromJS(action.records));
        case FETCH_MASTERNODE_STATS:
            return state.set('statsRecords', Immutable.fromJS(action.records)).set('statsRecordsLoading', false);
        case FETCH_MASTERNODE_STATS_LOADING:
            return state.set('statsRecordsLoading', true);
        case FETCH_MASTERNODES_AMOUNT:
            return state.set('masternodesAmount', Immutable.fromJS(action.masternodesAmount))
        default:
            return state;
    }
}
export default masternodeReducer;