import axios from 'axios';
import Immutable from 'immutable';
import { message } from 'antd';

const FETCH_CONTRACT_INFO = 'FETCH_CONTRACT_INFO';
const FETCH_CONTRACT_TXS = 'FETCH_CONTRACT_TXS';

import{ ITEMS_EVERY_PAGE, API_URL} from '../../conf'

// const apiUrl = 'http://133.130.116.161:8080/vds-api';
// const apiUrl = 'https://qtum.info/api';



export const fetchContractInfo = (address) => {
    return (dispatch) => {
        return axios.get(`${API_URL}/contract/${address}`)
        .then(response => {
            let contract = response.data;
            dispatch(fetchContractInfoSuccess(contract))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export const fetchContractInfoSuccess = (contract) => {
    return {
        type: FETCH_CONTRACT_INFO,
        contract
    };
};

export const fetchContractTxs = ({address, page=0, pageSize=10}) => {
    return (dispatch) => {
        return axios.get(`${API_URL}/contract/${address}/basic-txs`, {
            params: {
                page: page,
                pageSize: pageSize
            }
        })
        .then(response => {
            let contractTxsData = response.data;
            dispatch(fetchContractTxsSuccess(contractTxsData))
        })
        .catch(error => {
            throw(error);
        });
    };
};

export const fetchContractTxsSuccess = (contractTxsData) => {
    return {
        type: FETCH_CONTRACT_TXS,
        contractTxsData
    };
};


const initPoolState = Immutable.fromJS({
    contractInfo: {
        address: '',
        addressHex: '',
        decimals: 8,
        holders: 0,
        name: "",
        symbol: "",
        totalSupply: 0,
        transactions: 0
    },
    contractTxs: [],
    contractTxsAmount: 0
});

const contractReducer = (state = initPoolState, action) => {
    switch (action.type) {
        case FETCH_CONTRACT_INFO:
            return state.set('contractInfo', Immutable.fromJS(action.contract));
        case FETCH_CONTRACT_TXS:
            return state.set('contractTxs', Immutable.fromJS(action.contractTxsData.transactions)).set('contractTxsAmount', action.contractTxsData.totalCount);
        default:
            return state;
    }
}
export default contractReducer;