import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import cnLocaleData from 'react-intl/locale-data/zh';

import cnLang from './zh_CN';
import enLang from './en_US';

addLocaleData([...enLocaleData, ...cnLocaleData]);
addLocaleData(cnLocaleData);

const translationMessages = {
    "en": enLang,
    "zh": cnLang,
}
export default translationMessages;